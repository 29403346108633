import { Icon } from './Icon'

import { Content, ContentLink } from './Button.styles'
import { InputHTMLAttributes } from 'react'

interface Props extends InputHTMLAttributes<HTMLButtonElement> {
  type: 'button' | 'submit' | 'link'
  custom: 'primary' | 'secondary' | 'icon' | 'ghost' | 'danger'
  label?: string
  icon?: 'leading' | 'step' | 'link' | 'download' | 'exclude' | 'checked'
  iconAlign?: 'left' | 'right'
  link?: string
  handleClick?: () => void
}

export function Button({
  label,
  type,
  link,
  icon,
  iconAlign,
  custom,
  handleClick,
  ...rest
}: Props) {
  const renderPositionButton = () => {
    if (iconAlign === 'right' && iconAlign) {
      return (
        <>
          {label} <Icon icon={icon} />
        </>
      )
    } else {
      return (
        <>
          <Icon icon={icon} /> {label}
        </>
      )
    }
  }

  if (type === 'link') {
    return (
      <ContentLink to={link || ''} data-testid="link-button">
        <Icon icon={icon} /> {label}
      </ContentLink>
    )
  }

  return (
    <Content
      type={type}
      custom={custom}
      onClick={handleClick}
      iconAlign={iconAlign}
      data-testid="button"
      {...rest}
    >
      {renderPositionButton()}
    </Content>
  )
}

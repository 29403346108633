import { lazy } from 'react'
import { IRouteProps } from 'routes/CustomRoute.interface'
import { ROLES } from 'utils/roles'

const Transfer = lazy(() =>
  import('pages/Reports/Transfer').then(module => ({
    default: module.Transfer
  }))
)

const TransferConsolidated = lazy(() =>
  import('pages/Reports/TransferConsolidated').then(module => ({
    default: module.TransferConsolidated
  }))
)

const ProcessTransfer = lazy(() =>
  import('pages/Reports/Process').then(module => ({
    default: module.Process
  }))
)

export const ROUTE_TRANSFER_REPORT = '/reports/transfer'
export const ROUTE_TRANSFER_REPORT_CONSOLIDATED =
  '/reports/transfer/consolidated'
export const ROUTE_PROCESS_TRANSFER = '/reports/transfer/process'

export const reportRoutes: IRouteProps[] = [
  {
    path: ROUTE_TRANSFER_REPORT,
    exact: true,
    name: 'Transfer',
    component: Transfer,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Extratos e Relatórios'
        }
      ],
      title: 'Relatório de Repasse'
    }
  },
  {
    path: ROUTE_TRANSFER_REPORT_CONSOLIDATED,
    exact: true,
    name: 'Transfer Consolidated',
    component: TransferConsolidated,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Extratos e Relatórios'
        }
      ],
      title: 'Relatório de Repasse Consolidado'
    }
  },
  {
    path: ROUTE_PROCESS_TRANSFER,
    exact: true,
    name: 'Process Transfer',
    component: ProcessTransfer,
    isPrivate: true,
    roles: [ROLES.admin, ROLES.financial],
    breadcrumb: {
      items: [
        {
          label: 'Extratos e Relatórios'
        }
      ],
      title: 'Processar Repasse'
    }
  }
]

export default {
  color_background: '#FAFAFA',
  color_brand_darkest: '#143D2F',
  color_brand_dark: '#005034',
  color_brand_pure: '#29754D',
  color_brand_light: '#7EA08A',
  color_brand_lightest: '#E9F1ED',
  color_highlight_darkest: '#004309',
  color_highlight_dark: '#005A1B',
  color_highlight_pure: '#007033',
  color_highlight_light: '#3F9656',
  color_highlight_lightest: '#7DBB80',
  global_positive_dark: '#0A4726',
  global_positive_pure: '#138647',
  global_positive_light: '#EDFCF4',
  global_negative_dark: '#4F0230',
  global_negative_pure: '#DF0688',
  global_negative_lightest: '#DF0668c9',
  global_negative_light: '#FEEBF7',
  global_informative_dark: '#002D52',
  global_informative_pure: '#0076D6',
  global_informative_light: '#EBF6FF',
  global_neutral_black: '#1B1C22',
  global_neutral_darkest: '#363844',
  global_neutral_dark: '#494B5B',
  global_neutral_medium: '#71768E',
  global_neutral_light: '#CFD0D8',
  global_neutral_lightest: '#F4F4F6',
  global_neutral_white: '#FFFFFF',
  opacity_level_weakest: 0.16,
  opacity_level_weak: 0.32,
  opacity_level_intense: 0.64,
  opacity_level_semiopaque: 0.9,
  spacing_stack_nano: '4px',
  spacing_stack_micro: '8px',
  spacing_stack_tiny: '16px',
  spacing_stack_small: '24px',
  spacing_stack_medium: '40px',
  spacing_stack_large: '80px',
  spacing_stack_huge: '128px',
  spacing_stack_giant: '200px',
  spacing_inset_micro: '8px',
  spacing_inset_tiny: '16px',
  spacing_inset_small: '32px',
  spacing_inset_medium: '40px',
  spacing_squish_micro: '4px 8px',
  spacing_squish_tiny: '8px 16px',
  spacing_squish_small: '16px 32px',
  spacing_squish_medium: '16px 40px',
  border_radius_none: '0',
  border_radius_nano: '4px',
  border_radius_micro: '8px',
  border_radius_tiny: '12px',
  border_radius_small: '24px',
  border_radius_medium: '40px',
  border_radius_large: '80px',
  border_radius_circle: '100%',
  shadow_level_1:
    '0px 4px 10px rgba(207, 208, 216, 0.16), 0px 2px 8px rgba(113, 118, 142, 0.16)',
  shadow_level_2:
    '0px 42px 80px rgba(207, 208, 216, 0.16), 0px 9px 20px rgba(207, 208, 216, 0.16), 0px 3px 7px rgba(73, 75, 91, 0.16)',
  shadow_level_3:
    '0px 60px 54px rgba(207, 208, 216, 0.16), 0px 22px 26px rgba(207, 208, 216, 0.16), 0px 12px 18px rgba(207, 208, 216, 0.16), 0px 6px 12px rgba(113, 118, 142, 0.16)',
  shadow_level_4:
    '0px 15px 80px rgba(113, 118, 142, 0.16), 0px 7px 32px rgba(207, 208, 216, 0.16), 0px 4px 17px rgba(207, 208, 216, 0.16), 0px 0px 3px rgba(113, 118, 142, 0.16)',
  border_width_none: '0',
  border_width_hairline: '1px',
  border_width_thin: '2px',
  border_width_thick: '4px',
  border_width_heavy: '8px',
  font_family_headings: 'Montserrat, sans-serif',
  font_family_base: 'Open Sans, sans-serif',
  font_weights_black: 900,
  font_weights_extra_bold: 800,
  font_weights_bold: 700,
  font_weights_regular: 400,
  font_weights_light: 300,
  font_size_nano: '12px',
  font_size_micro: '14px',
  font_size_tiny: '16px',
  font_size_small: '20px',
  font_size_medium: '24px',
  font_size_large: '32px',
  font_size_huge: '40px',
  font_size_giant: '48px',
  font_size_giant_huge: '64px',
  line_height_default: '100%',
  line_height_tiny: '115%',
  line_height_small: '120%',
  line_height_medium: '133%',
  line_height_large: '150%',
  line_height_huge: '170%',
  letter_spacing_tight: '-2%',
  letter_spacing_default: '0%',
  letter_spacing_tiny: '3%'
}

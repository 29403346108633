import styled from 'styled-components'

export const Container = styled.div`
  height: 40px;
  padding: 0 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: ${props => props.theme.border.radius.micro};

  display: flex;
  align-items: center;
`

import styled, { css } from 'styled-components'

interface SearchBoxProps {
  opened: boolean
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: ${props => props.theme.border.radius.micro};
  flex: 1;
`

export const ToggleButton = styled.button`
  background-color: unset;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const ToggleButtonIcon = styled.img``

export const SearchBox = styled.div<SearchBoxProps>`
  display: flex;
  align-items: center;
  width: 0px;
  overflow: hidden;
  visibility: hidden;
  transition: width 0.3s;
  pointer-events: none;

  ${props =>
    props.opened &&
    css`
      margin-left: 5px;
      width: 340px;
      pointer-events: initial;
      overflow: visible;
      visibility: visible;
    `}
`

export const Input = styled.input`
  padding: 5px;
  background: none;
  border: 0;
  outline: 0;
  font-family: ${props => props.theme.font.family.base};
  font-size: ${props => props.theme.font.size.micro};
  font-weight: ${props => props.theme.font.weight.bold};
  color: ${props => props.theme.colors.global.neutral.white};
  flex: 1;
`

export const SearchButton = styled.button<SearchBoxProps>`
  padding: 2px 5px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: ${props => props.theme.border.radius.nano};
  opacity: 0;
  transition: opacity 0.4s;

  display: flex;
  justify-content: center;
  align-items: center;
  background: 0;
  font-family: ${props => props.theme.font.family.base};
  font-size: ${props => props.theme.font.size.micro};
  font-weight: ${props => props.theme.font.weight.bold};
  color: ${props => props.theme.colors.global.neutral.white};
  cursor: pointer;
  outline: 0;

  ${props =>
    props.opened &&
    css`
      opacity: 1;
    `}
`

export const SearchButtonIcon = styled.img`
  margin-left: 5px;
`

import iconLogo from 'assets/logo.svg'

import { InputSearch } from './InputSearch'
import { SelectOptions } from './SelectOptions'
import { Notification } from './Notification'

import { Container, Logo, Content } from './Header.styles'
import { Link } from 'react-router-dom'

export function Header() {
  return (
    <Container>
      <Link to="/">
        <Logo src={iconLogo} />
      </Link>

      <Content>
        <InputSearch />

        <SelectOptions />

        <Notification />
      </Content>
    </Container>
  )
}

import { ReactSVG } from 'react-svg'

import iconBell from 'assets/iconBell.svg'

import { Container } from './Notification.styles'

export function Notification() {
  return (
    <Container>
      <ReactSVG src={iconBell} />
    </Container>
  )
}

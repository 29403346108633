import styled, { css } from 'styled-components'

export const Container = styled.div`
  ${props => css`
    margin-bottom: 52px;

    .ant-breadcrumb {
      font-family: ${props.theme.font.family.base};
      font-size: ${props.theme.font.size.tiny};
      line-height: ${props.theme.line.height.large};

      span {
        span.ant-breadcrumb-link {
          color: ${props.theme.colors.brand.pure};
          font-weight: ${props.theme.font.weight.bold};

          a {
            color: ${props.theme.colors.brand.pure};
          }
        }
      }

      span.ant-breadcrumb-separator {
        color: ${props.theme.colors.global.neutral.medium};
        margin: 0 4px;
        position: relative;
        top: -1px;
      }
    }
  `}
`

export const Content = styled.div`
  display: flex;
  gap: 4px;
`

export const CustomSeparator = styled.div`
  ${props => css`
    font-family: ${props.theme.font.family.base};
    font-size: ${props.theme.font.size.tiny};
    line-height: ${props.theme.line.height.large};
    color: ${props.theme.colors.global.neutral.medium};
    position: relative;
    top: -1px;
  `}
`

export const Title = styled.h1`
  ${props => css`
    color: ${props.theme.colors.global.neutral.darkest};
    font-family: ${props.theme.font.family.base};
    font-size: ${props.theme.font.size.large};
    font-weight: ${props.theme.font.weight.regular};
    line-height: ${props.theme.line.height.large};
    letter-spacing: -0.1rem;
  `}
`

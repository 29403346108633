import { useCallback, useEffect, useRef, useState } from 'react'

import iconSearch from 'assets/iconSearch2.svg'
import iconEnter from 'assets/iconEnter.svg'

import {
  Container,
  ToggleButton,
  ToggleButtonIcon,
  SearchBox,
  Input,
  SearchButton,
  SearchButtonIcon
} from './InputSearch.styles'

export function InputSearch() {
  const inputRef = useRef<HTMLDivElement>(null)

  const [isActive, setIsActive] = useState(false)
  const [search, setSearch] = useState('')

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsActive(false)
      }
    }

    // Bind the event listener
    document.addEventListener('click', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside)
    }
  }, [inputRef])

  const handleSearch = useCallback(() => {
    console.log(`Searching by: ${search}`)
  }, [search])

  const handleOnKeyPress = useCallback(
    ({ key }) => {
      if (key === 'Enter') {
        handleSearch()
      }
    },
    [handleSearch]
  )

  return (
    <Container ref={inputRef} data-testid="input-search">
      <ToggleButton type="button" onClick={() => setIsActive(!isActive)}>
        <ToggleButtonIcon src={iconSearch} />
      </ToggleButton>

      <SearchBox opened={isActive}>
        <Input
          type="text"
          onChange={e => setSearch(e.target.value)}
          onKeyPress={handleOnKeyPress}
        />

        <SearchButton type="button" opened={isActive} onClick={handleSearch}>
          Enter
          <SearchButtonIcon src={iconEnter} />
        </SearchButton>
      </SearchBox>
    </Container>
  )
}
